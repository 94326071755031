@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.App {
  text-align: center;
  
}

.background {
  background-color: #362a179a;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

p {
  font-size: 20px;
  display: inline;
  padding: 2px;
  margin-right: 10px;
  margin-bottom: 20px;
}

/* Projects Page */

.toolsContainer {
  background-color: #b6ae99;
  padding-top: 1.75%;
  padding-left: 2%;
  padding-right: 2%;
  border-radius: 0.5rem;
  filter: drop-shadow(5px 5px 15px rgb(43, 41, 41))
}

/* File Explorer */

.fileDisplay /* For the file display element buttons */ {
  text-align: center;
  display: inline;
  width: 45%;
  margin: 1%;
}

.fileUpload /* For the file display element buttons */ {
  display: inline;
  width: 100%;
  margin: 10px;
  font-size: 75%;
}

.filesListed /* For the file display element buttons */ {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
}

.fileReturned {
  display: flex;
  flex-direction: column;
  inline-size: min-content;
  justify-content: top;
  align-items: center;
  font-size: 2rem;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  transition: all 500ms;
  overflow: wrap;
  word-break: break-all;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  color: #ffffff;
}

.fileReturnedText {
  margin: 0px;
  word-break: break-all;
  text-align: center;
  font-size: 40%;
  padding-bottom: 15px;
}

.fileExplorerButton {
  font-size: 100%;
  margin-right: 5%;
  padding: 5px;
  padding-left: 15px;
  padding-right: 17px;
  width: 50px;
  background-color: #31333f;
  border-color: #2b2d31;
  color: #ffffff;
}

/* Xml to Json */

.xmlToJsonInput {
  background-color: #26272c;
  color: white;
}


.socialMedia {
  font-size: 100%;
}

.socialMediaIcon {
  font-size: 150%;
  padding: 0px;
}

.navTitle {
  font-size: 130%;
}

/* Nav */
.nav-name {
  color: #241e15
}

.nav-link {
  color: #212646
}


/* OVERRIDES */
.overrideArticle {
  padding-top: 2%;
  padding-bottom: 2%;
  background-color: #dfdace;
  filter: drop-shadow(0px 0px 5px rgb(49, 49, 49))
  /* background-color: #191a1f */
}

.overrideHeading {
  color: #000000;
  font-family: 'Open Sans', sans-serif;
}

.overrideSubHeading {
  color: #000000;
}

.white-text {
  color: rgb(0, 0, 0);
  font-family: 'Open Sans', sans-serif;
}

.portrait {
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: rgba(211, 211, 211, 0.925);
  filter: drop-shadow( 7px 7px 0px rgba(65, 65, 65, 0.705))
}

.homeIntro {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.homeIntroContainer {
  background-color: #212646;
}

.filePreview {
  position: absolute;
  left: vw / 2;
  top: vh / 2;
  transform: translate(vh / 2, vw /2);
  width: 10000px;
}